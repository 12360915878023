@import "src/colors.less";

.ant-card.add,
.ant-card.main-desc,
.ant-card.quote {
  height: 100%;
}

.app-light .ant-card.add,
.app-dark .ant-card.add {
  background-color: @half-baked;
  display: flex;
  justify-content: center;
  flex-direction: column;
  box-shadow: 0px 3px 6px #00000029;
  .ant-card-body{
    padding: 0;
    .bigAddButton {
      cursor: pointer;
      padding: 24px;
      background-color: @half-baked;
      color: @big-stone;
      border-radius: 3px;
      display: flex;
      flex-direction: column;
      align-items: center;
      font-style: normal;
      font-variant: normal;
      font-weight: bold;
      font-size: 3rem;
      text-align: center;
    }
  }
  &:hover {
    box-shadow: 12px -6px 7px -7px @half-baked;
  }
}

@primary-color: #1890ff;@link-color: #1890ff;@success-color: #52c41a;@warning-color: #faad14;@error-color: #f5222d;@font-size-base: 14px;@heading-color: rgba(0, 0, 0, 0.85);@text-color: rgba(0, 0, 0, 0.65);@text-color-secondary: rgba(0, 0, 0, 0.45);@disabled-color: rgba(0, 0, 0, 0.25);@border-radius-base: 2px;@border-color-base: #d9d9d9;@box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);