@import "src/colors.less";

.footer .App-footer {
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: 15px;
  li.ant-list-item {
    font-size: 1.2rem;
  }
  li.ant-list-item span {
    padding-right: 5px;
    text-decoration: underline;
  }
  a {
    color: @white;
  }
  .welcome {
    text-align: center;
    align-self: center;
    font-size: 2.2rem;
    line-height: 3vmax;
    font-family: "Gochi Hand", cursive;
    max-height: 140px;
  }
  .links {
    padding-top: 15px;
  }
  .app-image {
    overflow-y: hidden;
    position: absolute;
    right: 15px;
  }
}

@primary-color: #1890ff;@link-color: #1890ff;@success-color: #52c41a;@warning-color: #faad14;@error-color: #f5222d;@font-size-base: 14px;@heading-color: rgba(0, 0, 0, 0.85);@text-color: rgba(0, 0, 0, 0.65);@text-color-secondary: rgba(0, 0, 0, 0.45);@disabled-color: rgba(0, 0, 0, 0.25);@border-radius-base: 2px;@border-color-base: #d9d9d9;@box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);