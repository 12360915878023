@import "~antd/dist/antd.less";
@import "src/colors.less";


.ant-modal-root .ant-modal-wrap .ant-modal, .ant-modal-mask {
    .bbb-btn-light-primary.multi-line, .multi-line .ant-btn {
        white-space: normal;
        height: auto;
        margin: 5px 10px 0px 10px;
    }
    
    div.grid {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
        grid-gap: 16px;
        justify-content: space-between;
        .selected {
            box-shadow: 0px 0px 6px 6px @half-baked;
        }
    }
}

@primary-color: #1890ff;@link-color: #1890ff;@success-color: #52c41a;@warning-color: #faad14;@error-color: #f5222d;@font-size-base: 14px;@heading-color: rgba(0, 0, 0, 0.85);@text-color: rgba(0, 0, 0, 0.65);@text-color-secondary: rgba(0, 0, 0, 0.45);@disabled-color: rgba(0, 0, 0, 0.25);@border-radius-base: 2px;@border-color-base: #d9d9d9;@box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);