@import "~antd/lib/style/themes/default.less";
@import "src/colors.less";

div.grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-gap: 16px;
    grid-auto-rows: 1fr;
    .ant-card {
        height: 100%;
    }
    .loadMore{
        margin-top: 4rem;
    }
}

.ant-card.news {
    cursor: pointer;
    .ant-card-body{
        padding: 12px;
        &.ant-space{
            width: 100%;
        }
    }
    
}

@primary-color: #1890ff;@link-color: #1890ff;@success-color: #52c41a;@warning-color: #faad14;@error-color: #f5222d;@font-size-base: 14px;@heading-color: rgba(0, 0, 0, 0.85);@text-color: rgba(0, 0, 0, 0.65);@text-color-secondary: rgba(0, 0, 0, 0.45);@disabled-color: rgba(0, 0, 0, 0.25);@border-radius-base: 2px;@border-color-base: #d9d9d9;@box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);