@import "~antd/dist/antd.less";

.bbbCarousel {
  .page {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 30px 0px;
    margin: 5px;
    height: 100%;
    .ant-form-item {
      .ant-form-item-label {
        display: flex;
        max-width: none;
      }
      &.checkbox.ant-form-item {
        .ant-form-item-label,
        .ant-form-item-control {
          flex-basis: 45%;
          display: flex;
        }
      }
    }
  }
  .actions {
    text-align: center;
  }
}
@primary-color: #1890ff;@link-color: #1890ff;@success-color: #52c41a;@warning-color: #faad14;@error-color: #f5222d;@font-size-base: 14px;@heading-color: rgba(0, 0, 0, 0.85);@text-color: rgba(0, 0, 0, 0.65);@text-color-secondary: rgba(0, 0, 0, 0.45);@disabled-color: rgba(0, 0, 0, 0.25);@border-radius-base: 2px;@border-color-base: #d9d9d9;@box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);