@import "~antd/dist/antd.less";
@import "src/colors.less";

.app-light .ant-card.birthRegistryListItem,
.app-dark .ant-card.birthRegistryListItem {
  .ant-card-body {
    display: flex;
    justify-content: space-between;
    .flex-content {
      cursor: pointer;
      flex-grow: 1;
      .flex-head {
        padding: 15px 15px;
        font-weight: bold;
        font-size: 2rem;
        text-overflow: ellipsis;
      }
      .flex-body {
        padding-bottom: 15px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .description {
          flex-grow: 1;
          .ant-typography {
            color: inherit;
            &.italic {
              font-style: italic;
            }
          }
        }
      }
    }
    .actions {
      min-height: 8rem;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      flex-shrink: 0;
    }
  }
}

@media screen {
  @media (max-width: @screen-sm) {
    // until 576px
    .app-light .ant-card.birthRegistryListItem,
    .app-dark .ant-card.birthRegistryListItem {
      .ant-card-body .flex-content .flex-body .figure {
        padding-right: 8.5rem;
      }
      .actions button {
        font-size: 12px;
      }
    }
  }
}

@primary-color: #1890ff;@link-color: #1890ff;@success-color: #52c41a;@warning-color: #faad14;@error-color: #f5222d;@font-size-base: 14px;@heading-color: rgba(0, 0, 0, 0.85);@text-color: rgba(0, 0, 0, 0.65);@text-color-secondary: rgba(0, 0, 0, 0.45);@disabled-color: rgba(0, 0, 0, 0.25);@border-radius-base: 2px;@border-color-base: #d9d9d9;@box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);