@import "~antd/lib/style/themes/default.less";
@import "src/colors.less";

@media (min-width: @screen-xs) {
  .cardContent {
    min-height: 100px;
  }
}
@media (min-width: @screen-lg) {
  .cardContent {
    min-height: 420px;
  }
}

.ant-card.cardContent {
  color: @black;
  height: 100%;
  & .ant-card-body {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    font-style: normal;
    font-variant: normal;
    & .title {
      font-weight: bold;
      margin-bottom: 3rem;
    }
    & .subTitle {
      font-size: 1.3rem;
      margin-bottom: 2rem;
    }
    & .actionsCard {
      & .ant-button {
        font-weight: bold;
        font-size: 2rem;
      }
    }
    & .searchResult {
      max-height: 20rem;
      overflow-y: auto;
    }
  }
}

@primary-color: #1890ff;@link-color: #1890ff;@success-color: #52c41a;@warning-color: #faad14;@error-color: #f5222d;@font-size-base: 14px;@heading-color: rgba(0, 0, 0, 0.85);@text-color: rgba(0, 0, 0, 0.65);@text-color-secondary: rgba(0, 0, 0, 0.45);@disabled-color: rgba(0, 0, 0, 0.25);@border-radius-base: 2px;@border-color-base: #d9d9d9;@box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);