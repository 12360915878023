@import "src/colors.less";
.main-desc.ant-card {
  font-size: 1.3rem;
  line-height: 2.2rem;
  font-family: Nunito;
  font-weight: 900;
  .cover {
    display: flex;
    align-items: center;
    height: 100%;
    .logo {
      max-height: 30rem;
      max-width: 100%;
    }
  }
  .name {
    font-size: 2.2rem;
    margin-bottom: 0.5rem;
  }
  a {
    color: @big-stone;
    font-size: 2.0rem;
  }

}

@primary-color: #1890ff;@link-color: #1890ff;@success-color: #52c41a;@warning-color: #faad14;@error-color: #f5222d;@font-size-base: 14px;@heading-color: rgba(0, 0, 0, 0.85);@text-color: rgba(0, 0, 0, 0.65);@text-color-secondary: rgba(0, 0, 0, 0.45);@disabled-color: rgba(0, 0, 0, 0.25);@border-radius-base: 2px;@border-color-base: #d9d9d9;@box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);