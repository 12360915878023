@import "~antd/lib/style/themes/default.less";
@import "src/colors.less";

.app-light .ant-card.wishItem,
.app-dark .ant-card.wishItem {
  min-height: 350px;
  .ant-card-head {
    color: @black;
    padding: 0 15px;
    font-size: 1.2rem;
    font-weight: bold;
  }
  .ant-card-body {
    color: @blue-bayoux-contrast;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    min-height: 290px;
    padding: 0px;
    .body {
      min-height: 260px;
      color: @dove-gray;
      .favorite-banner {
        font-style: oblique;
        background-color: @sundown;
      }
      .description {
        text-align: justify;
        a {
          color: @blue-bayoux-contrast;
        }
      }
      .figure{
        position: relative;
        padding-bottom: 100%;
        margin-bottom: 0rem;
        .picture {
          position: absolute;
          margin: 0 auto;
          overflow: hidden;
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          .logo {
            height: auto;
            max-height: 100%;
            max-width: 100%;
          }
        }
      }
      .price {
        padding: 10px 10px 0px;
      }
      .booked-banner {
        background-color: @pink;
        position: absolute;
        bottom: 40px;
        width: 50%;
        box-shadow: 0px 3px 6px @black-with-transparency;
        border-radius: 0 5px 5px 0;
        padding-left: 10px;
      }
    }
  }
  &:hover {
    box-shadow: 12px -6px 7px -7px @half-baked;
  }
}

.ant-popover {
  .ant-popover-inner-content {
    .wishItemPop {
      display: flex;
      flex-direction: column;
    }
  }
}

@media screen {
  @media (max-width: @screen-sm) {
    .ant-card.wishItem .ant-card-head .ant-card-head-title {
      font-size: 13px;
    }
  }
}

@primary-color: #1890ff;@link-color: #1890ff;@success-color: #52c41a;@warning-color: #faad14;@error-color: #f5222d;@font-size-base: 14px;@heading-color: rgba(0, 0, 0, 0.85);@text-color: rgba(0, 0, 0, 0.65);@text-color-secondary: rgba(0, 0, 0, 0.45);@disabled-color: rgba(0, 0, 0, 0.25);@border-radius-base: 2px;@border-color-base: #d9d9d9;@box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);