@import "src/colors.less";
.ant-card.bookingCard {
  margin-top: 20px;
  .ant-card-head {
    background-color: @seashell-peach;
    font-weight: bold;
    font-size: 2rem;
  }
  .image-row {
    min-height: 300px;
    .bear-image {
      width: 20rem;
    }
    .bubble {
      position: relative;
      padding: 15px;
      margin: 0 20px;
      height: 100%;
      width: 250px;
      text-align: justify;
      color: @black;
      white-space: pre-wrap;
      background: @half-baked;
      border: 3px solid @blue-bayoux;
      border-radius: 10px;
      &::before {
        content: "";
        position: absolute;
        top: 20%;
        left: -20px;
        height: 20px;
        width: 20px;
        background-color: @half-baked;
        box-sizing: border-box;
        transform: rotate(135deg) translate(-50%);
        border-bottom: inherit;
        border-right: inherit;
        box-shadow: inherit;
      }
    }
  }
  .signal .ant-typography u {
    color: @half-baked;
  }
}

@primary-color: #1890ff;@link-color: #1890ff;@success-color: #52c41a;@warning-color: #faad14;@error-color: #f5222d;@font-size-base: 14px;@heading-color: rgba(0, 0, 0, 0.85);@text-color: rgba(0, 0, 0, 0.65);@text-color-secondary: rgba(0, 0, 0, 0.45);@disabled-color: rgba(0, 0, 0, 0.25);@border-radius-base: 2px;@border-color-base: #d9d9d9;@box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);