@import "~antd/lib/style/themes/default.less";
@import "src/colors.less";

.punchlines{
    .ant-card-body {
        padding-bottom: 20px;
        .ant-carousel .slick-dots li {
            height: 6px;
            & button {
                height: 4px;
            }
        }
        .mt20 {
            margin-top: 3rem;
            .grid {
                display: flex;
                flex-direction: row;
                @media (max-width: @screen-md) {
                    // 768 to 992px
                    flex-direction: column;
                }
                .image {
                    margin: auto;
                    flex-grow: 0;
                }
                .text {
                    flex-grow: 1;
                }
            }
        }
        .ant-carousel .slick-dots-bottom {
            bottom: 0;
        }
    }
}

@primary-color: #1890ff;@link-color: #1890ff;@success-color: #52c41a;@warning-color: #faad14;@error-color: #f5222d;@font-size-base: 14px;@heading-color: rgba(0, 0, 0, 0.85);@text-color: rgba(0, 0, 0, 0.65);@text-color-secondary: rgba(0, 0, 0, 0.45);@disabled-color: rgba(0, 0, 0, 0.25);@border-radius-base: 2px;@border-color-base: #d9d9d9;@box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);