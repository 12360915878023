@import "~antd/lib/style/themes/default.less";
@import "src/colors.less";

.ant-card.searchBar {
  margin-top: 3rem;
  .ant-row {
    width: 100%;
  }
}

@media screen {
  @media (max-width: @screen-sm) {
    .ant-card.searchBar{
      .ant-card-body {
        padding: 10px;
      }
      .check.ant-form-item .ant-form-item-label {
        flex-basis: 60%;
      }
      .check.ant-form-item .ant-form-item-control {
        flex-basis: 40%;
        padding-left: 10px;
      }
    }
  }
}

@primary-color: #1890ff;@link-color: #1890ff;@success-color: #52c41a;@warning-color: #faad14;@error-color: #f5222d;@font-size-base: 14px;@heading-color: rgba(0, 0, 0, 0.85);@text-color: rgba(0, 0, 0, 0.65);@text-color-secondary: rgba(0, 0, 0, 0.45);@disabled-color: rgba(0, 0, 0, 0.25);@border-radius-base: 2px;@border-color-base: #d9d9d9;@box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);