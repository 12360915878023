@import "~antd/lib/style/themes/default.less";

.ant-card.quote {
  text-align: center;
  font-size: 2rem;
  line-height: 2rem;
  font-family: "Gochi Hand", cursive;
  white-space: pre-wrap;

  img.pin {
    bottom: 4rem;
    position: relative;
  }
}

@media screen {
  @media (max-width: @screen-sm) {
    .ant-card.quote {
      font-size: 1.5rem;
      line-height: 1.5rem;
      .ant-card-body {
        padding: 10px;
      }
      img.pin {
        bottom: 1rem;
      }
    }
  }
}
@primary-color: #1890ff;@link-color: #1890ff;@success-color: #52c41a;@warning-color: #faad14;@error-color: #f5222d;@font-size-base: 14px;@heading-color: rgba(0, 0, 0, 0.85);@text-color: rgba(0, 0, 0, 0.65);@text-color-secondary: rgba(0, 0, 0, 0.45);@disabled-color: rgba(0, 0, 0, 0.25);@border-radius-base: 2px;@border-color-base: #d9d9d9;@box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);