@import "~antd/dist/antd.less";
@import "src/colors.less";

html {
  font-family: "Nunito", sans-serif;
  font-weight: normal;
  height: 100%;
  width: 100%;
  min-width: 360px;
  --scroll-bar: 0;

  body {
    font-family: "Nunito", sans-serif;
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100%;
    width: 100%;
  }

  /* Utility classes */
  .ml10 {
    margin-left: 10px;
  }
  .mr10 {
    margin-right: 10px;
  }
  .mt10 {
    margin-top: 10px;
  }
  .mb10 {
    margin-bottom: 10px;
  }

  .hidden {
    visibility: hidden;
  }
  .full-width {
    width: 100%;
  }
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
  }
  .red, .anticon.red {
    color: @torch-red;
  }

  /* Override Carousel antd */
  .ant-carousel {
    .slick-dots {
      height: 10px;
      & li {
        background-color: @half-baked;
        opacity: 0.80;
        &.slick-active {
          background-color: @big-stone;
          opacity: 0.80;
          & button{
            background-color: inherit;
          }
        }
        
      }

    }
  }

  /* Override buttons in modal */
  .ant-modal-root .bbb-btn-light-primary, .ant-modal-root .bbb-btn-light-primary:hover {
    background: @half-baked 0% 0% no-repeat padding-box;
    font-style: normal;
    font-variant: normal;
    font-weight: bold;
    border: 0;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 2px;
    opacity: 1;
    color: @big-stone;
    &:disabled {
      background: @silver-chalice;
    }
  }

  /* Responsive */
  .App {
    .ant-layout-footer {
      padding: 0 30px 30px 30px;
    }
    &.app-dark {
      color: rgba(255, 255, 255, 0.65);

      & > * {
        background: #001529;
      }
    }
    &.app-light {
      &.ant-layout {
        background: white;
      }
      .ant-card {
        box-shadow: 5px -6px 11px -7px @black-with-transparency;
      }
      .ant-layout-footer {
        background-color: @blue-bayoux;
      }
    }
  }
}


@media screen {
  @media (max-width: @screen-xs) {
    // until 480px
    html {
      font-size: 8px;
      .App-header {
        height: 200px;
      }
      .content {
        min-height: 79vh;
        padding: 80px 10px;
      }
    }
  }
  @media (min-width: @screen-xs) {
    // 480 to 576px
    html {
      font-size: 9px;
      .App-header {
        height: 250px;
      }
      .content {
        min-height: 75vh;
        padding: 60px 30px;
      }
    }
  }
  @media (min-width: @screen-sm) {
    // 576 to 768px
    html {
      font-size: 9px;
      .App-header {
        height: 330px;
        .personalMenu {
          position: relative;
          bottom: 55px;
        }
        .subPages {
          position: relative;
          bottom: 55px;
        }
      }
      .content {
        min-height: 67vh;
        padding: 80px 30px;
      }
    }
  }
  @media (min-width: @screen-md) {
    // 768 to 992px
    html {
      font-size: 10px;
      .App-header {
        height: 300px;
        .personalMenu {
          margin-left: 0;
          bottom: 0px;
        }
        .subPages {
          bottom: 0px;
        }
      }
      .content {
        min-height: 72vh;
        padding: 50px;
      }
    }
  }
  @media (min-width: @screen-lg) {
    // 992 to 1200px
    html {
      font-size: 11px;
      .App-header {
        height: 330px;
      }
    }
  }
  @media (min-width: @screen-xl) {
    // 1200 to 1600px
    html {
      font-size: 12px;
    }
    .content {
      min-height: 78.9vh;
    }
  }
  @media (min-width: @screen-xxl) {
    // above 1600px
    html {
      font-size: 14px;
      .App-header {
        height: 330px;
        .personalMenu {
          order: 4;
        }
        .subPages {
          order: 3;
        }
      }
    }
  }
}


@primary-color: #1890ff;@link-color: #1890ff;@success-color: #52c41a;@warning-color: #faad14;@error-color: #f5222d;@font-size-base: 14px;@heading-color: rgba(0, 0, 0, 0.85);@text-color: rgba(0, 0, 0, 0.65);@text-color-secondary: rgba(0, 0, 0, 0.45);@disabled-color: rgba(0, 0, 0, 0.25);@border-radius-base: 2px;@border-color-base: #d9d9d9;@box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);