@import "~antd/lib/style/themes/default.less";
@import "src/colors.less";

.App {
  .ant-btn {
      height: 35px;
      line-height: normal;
    }
  &.app-dark {
    .ant-btn-primary {

    }
  }
  &.app-light {
    .ant-btn-primary {
      background: @half-baked 0% 0% no-repeat padding-box;
      font-style: normal;
      font-variant: normal;
      font-weight: bold;
      border: 0;
      box-shadow: 0px 3px 6px #00000029;
      opacity: 1;
      color: @big-stone;
      &:disabled {
        background: @silver-chalice;
      }
    }
  }
  .ant-input-group-addon:has(> .ant-btn) {  
    border: 0;
    padding: 0; 
  }
}

@media screen {
  @media (max-width: @screen-xs) {
    .App {
      &.app-light {
        .ant-btn-primary {
          font-size: 1.9rem;
        }
      }
    }
  }
  @media (min-width: @screen-xs) {
    .App {
      &.app-light {
        .ant-btn-primary {
          font-size: 18px;
        }
      }
    }
  }
}

@primary-color: #1890ff;@link-color: #1890ff;@success-color: #52c41a;@warning-color: #faad14;@error-color: #f5222d;@font-size-base: 14px;@heading-color: rgba(0, 0, 0, 0.85);@text-color: rgba(0, 0, 0, 0.65);@text-color-secondary: rgba(0, 0, 0, 0.45);@disabled-color: rgba(0, 0, 0, 0.25);@border-radius-base: 2px;@border-color-base: #d9d9d9;@box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);