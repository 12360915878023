@import "src/colors.less";
@import "~antd/dist/antd.less";

.App-header {
  background-color: @half-baked-contrast;
  border-bottom: 1px solid #e8e8e8;
  height: 330px;
  margin-bottom: -170px;
  padding: 0 50px;
  font-style: normal;
  font-variant: normal;
  font-weight: bold;
  .ant-badge {
    color: @blue-bayoux-contrast;
    opacity: 1;
  }
  a {
    color: @blue-bayoux-contrast;
  }

  .app-logo {
    width: 100%;
    margin-top: 0.3em;
    pointer-events: none;
  }

  .ant-menu-horizontal {
    border-bottom: none;

    &.ant-menu-light .ant-menu-item.ant-menu-item-selected,
    &.ant-menu-light > .ant-menu-item:hover,
    &.ant-menu-dark .ant-menu-item.ant-menu-item-selected,
    &.ant-menu-dark > .ant-menu-item:hover {
      color: var(--primary-color);
      border-bottom: 2px solid var(--primary-color);
    }

    &.ant-menu-light > .ant-menu-item,
    &.ant-menu-light.ant-menu-horizontal > .ant-menu-submenu,
    &.ant-menu-dark > .ant-menu-item,
    &.ant-menu-dark.ant-menu-horizontal > .ant-menu-submenu {
      border-bottom: 2px solid transparent;
    }
  }

  .ant-menu-light {
    background: none;
    &.ant-menu-vertical {
      border-right-width: 0;
    }
  }

  .ant-menu {
    transition: none;
  }
}

.ant-menu-horizontal > .ant-menu-submenu .ant-menu-submenu-title:hover,
.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: transparent !important; // Force background color of menu item whatever theme is !
}

@media screen {
  @media (max-width: @screen-xs) {
    // until 480px
    .App-header {
      font-size: 14px;
      line-height: 25px;
      .ant-badge {
        font-size: 14px;
      }
    }
  }
  @media (min-width: @screen-xs) {
    // 480 to 576px
    .App-header {
      font-size: 18px;
      line-height: 28px;
      .ant-badge {
        font-size: 18px;
      }
    }
  }
  @media (min-width: @screen-sm) {
    // 576 to 768px
    .App-header {
      font-size: 20px;
      line-height: 30px;
      .ant-badge {
        font-size: 20px;
      }
    }
  }
  @media (min-width: @screen-md) {
    // 768 to 992px
  }
  @media (min-width: @screen-lg) {
    // 992 to 1200px
    .App-header {
      font-size: 21px;
      line-height: 33px;
      .ant-badge {
        font-size: 21px;
      }
    }
  }
  @media (min-width: @screen-xl) {
    // 1200 to 1600px
    .App-header {
      font-size: 22px;
      line-height: 40px;
      .ant-badge {
        font-size: 22px;
      }
    }
  }
  @media (min-width: @screen-xxl) {
    // above 1600px

  }
}


@primary-color: #1890ff;@link-color: #1890ff;@success-color: #52c41a;@warning-color: #faad14;@error-color: #f5222d;@font-size-base: 14px;@heading-color: rgba(0, 0, 0, 0.85);@text-color: rgba(0, 0, 0, 0.65);@text-color-secondary: rgba(0, 0, 0, 0.45);@disabled-color: rgba(0, 0, 0, 0.25);@border-radius-base: 2px;@border-color-base: #d9d9d9;@box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);